.img-wind {
    line-height: 31px;
}

.wind-img img {
    background-repeat: no-repeat;
    background-position: 7px 7px;
    background-size: 10px;
}

.wind-text {
    font-size: 13px;
}

.wind-text span {
    font-size: 16px;
}

@media (max-width: 64rem) {
    .img-wind .bft {
        display: none;
    }
}