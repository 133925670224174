.breadcrumbs {
    padding: .5rem 1rem;
}

.breadcrumbs ul {
    line-height: 32px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.breadcrumbs ul li {
    display: inline-block;
}

.breadcrumbs ul li a {
    display: inline-block;
}

.breadcrumbs ul li a.last {
    color: #4e5f70;
    font-weight: bold;
    /*text-transform: capitalize;*/
}

.breadcrumbs ul li a.last:hover {
    cursor: default;
    text-decoration: none;
}

.breadcrumbs ul li img {
    display: inline;
    padding: 0 10px;
    vertical-align: middle;
}

.breadcrumbs ul li a img.wxco.small {
    vertical-align: top;
}

@media (max-width: 40em) {
    .breadcrumbs {
        padding: .5rem;
    }

    .breadcrumbs ul li img {
        padding: 0 5px;
    }
}