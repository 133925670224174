.tabs-footer {
    background-color: #FFFFFF;
    border-top: solid 1px #d4d9e3;
    bottom: 0;
    height: 60px;
    padding: 0;
    position: fixed;
    z-index: 3;
}

.tabs-footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tabs-footer ul li {
    text-align: center;
    padding: 0;
}

.tabs-footer ul li a {
    color: #fff;
    display: block;
    height: 60px;
    background-color: #81A0BE;
    border-right: solid 1px rgba(41, 69, 96, .3);
}
.tabs-footer ul li:last-child a {
    border-right: none;
}

.tabs-footer ul li.active a {
    background-color: #294560;
}

.tabs-footer ul li img {
    display: block;
    margin: 0 auto 5px auto;
    padding-top: 8px;
}