.fullday-widget-maker iframe {
    width: 100%;
    min-height: 300px;
}

.fullday-widget-maker .code-copy #code {
    border: solid 1px #e2e7ed;
    border-radius: 5px;
    padding: 5px;
}

.fullday-widget-maker .code-copy .copy-code {
    position: relative;
}

.fullday-widget-maker .code-copy .badge {
    margin-bottom: 0px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: -20%;
    width: 160px;
}