.location-activity table tr th {
    border-bottom: none;
    border-right: solid 1px #f2f3f5;
    padding: .25rem .5rem;
    position: relative;
    text-align: left;
    line-height: 30px;
}

.location-activity tr th img {
    float: left;
    width: 36px;
    height: 36px;
    padding-right: .5rem;
}

.location-activity tr th span {
    vertical-align: sub;
}

.location-activity table tr td {
    padding: .25rem .5rem;
}

.location-activity tr td .circle {
    border-radius: 15px;
    color: #FFFFFF;
    display: block;
    height: 30px;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 30px;
}