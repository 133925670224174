/* header */
.header {
    display: flex;
    background-color: #2F445E;
    width: 100%;
    padding: 0 .625rem;
    box-sizing: border-box;
    height: 60px;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}

.main-menu-item a {
    color: white;
    display: block;
    padding: 1.5rem .625rem;
    text-decoration: none;
}

.main-menu-item a:hover,
.header .menu-btn:hover {
    background-color: #4f6682;
}

.header .logo {
    display: flex;
    align-items: center;
}

.header .logo img {
    width: 250px;
}

/* menu */
.header .main-menu {
    display: none;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    transition: .2s;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 2;
}

.search .location-search .color {
    background-color: transparent;
    padding: 0;
}

.search .location-search .content {
    padding-bottom: 0;
}

/* menu icon */
.header .menu-icon {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-left: auto;
    width: 40px;
}

/* menu btn */
.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .main-menu {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #3b506a;
    background-color: #2F445E;
    opacity: 1;
    max-height: 100vh;
    max-width: none;
    box-shadow: 0px 4px 5px 0px #a2a2a2;
}

.header .menu-btn:checked ~ .menu-icon .bars {
    display: none;
}

.header .menu-btn:not(:checked) ~ .menu-icon .bars {
    display: block;
}

.header .menu-btn:checked ~ .menu-icon .close {
    display: block;
}

.header .menu-btn:not(:checked) ~ .menu-icon .close {
    display: none;
}

@media (min-width: 640px) {
    .header {
        padding: 0 1rem;
    }

    .main-menu-item a {
        padding: 1.5rem 16px;
    }
}

@media (min-width: 768px) {
    .header {
        height: 124px;
        padding: 1rem;
    }

    .header .logo {
        width: 100%;
        max-height: 45px;
        justify-content: center;
    }

    .main-menu-item {
        margin-right: 15px;
    }

    .main-menu-item a {
        padding: 0;
        font-size: 14px;
    }

    .main-menu-item a:hover,
    .header .menu-btn:hover {
        text-decoration: underline;
        background-color: transparent;
    }

    .header .main-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-left: auto;
        opacity: 1;
        max-height: none;
        max-width: none;
    }

    .header .main-menu .search {
        padding: 0;
    }

    .header .main-menu .search .padding {
        padding: .625rem 0;
    }

    .header .main-menu-items {
        display: flex;
    }

    .header .menu-icon {
        display: none;
    }
}

@media (min-width: 1024px) {
    .header {
        height: 80px;
    }

    .header .logo {
        width: unset;
        max-height: unset;
    }

    .header .main-menu {
        position: inherit;
    }
}

@media (min-width: 1150px) {
    .main-menu-item a {
        font-size: 16px;
    }
}
