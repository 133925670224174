.region-location-list .columns {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
    list-style: none;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 40em) {
    .region-location-list .columns {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
}