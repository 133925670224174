.actual-forecast .title h1 {
    display: inline-block;
}

.actual-forecast .block .content {
    padding-top: 0;
}

.actual-forecast .subtitle {
    display: flex;
}

.actual-forecast .subtitle div {
    display: inline-block;
}

.actual-forecast .subtitle div:last-child {
    margin-left: auto;
}

.actual-forecast .subtitle .weather-text div {
    color: #4e5f70;
}

.actual-forecast .weather {
    margin-bottom: 2rem;
}

.actual-forecast .weather .icon {
    padding: 1.4rem 0 .8rem 0;
}

.actual-forecast .weather .icon .wxco {
    float: left;
    margin: 0 .5rem 0 0;
}

.actual-forecast .weather .icon .temp {
    color: #92A5BB;
    font-size: 96px;
    line-height: 96px;
}

.actual-forecast .data .row {
    margin-top: 1rem;
}

.actual-forecast .data .rain {    
    display: inline-block;
    padding: 10px 0 10px 16px;
}

.actual-forecast .data .not-raining {
    background-color: #F7F8FA;
    display: inline-block;
    padding: 10px 0 10px 16px;
}

.actual-forecast .data .rain-data {
    height: 32px;
    line-height: 32px;
}

.actual-forecast .data .not-raining .humidity {
    padding-left: 0;
}

.actual-forecast .data .precip span {
    font-weight: normal;
}

.actual-forecast .data .humidity {
    color: #4e5f70;
}

.actual-forecast .data .wind {
    background-color: #F7F8FA;
    display: inline-block;
    padding: 10px 0 10px 16px;
}

.actual-forecast .data .rain .rain-img,
.actual-forecast .data .rain .rain-data {
    display: table-cell;
    vertical-align: top;
}
.actual-forecast .data .rain .rain-img {
    width: 28px;
}
.actual-forecast .data .rain .rain-img img {
    width: 20px;
    height: 17px;
    margin-top: 8px;
}

.actual-forecast .data hr {
    margin: 0.5rem 0;
}

.actual-forecast .short-term h2 {
    margin-bottom: 1rem;
}

@media (max-width: 64em) {
    .actual-forecast .weather {     
        margin-bottom: .5rem;
    }

    .actual-forecast .weather .icon {
        padding: .8rem 0;
    }

    .actual-forecast .weather .icon img {
        width: 72px;
        height: 72px;
    }

    .actual-forecast .weather .icon .temp {
        font-size: 72px;
        line-height: 72px;
    }

    .actual-forecast .weather .data .col.row {
        margin-top: 0;
    }
}
