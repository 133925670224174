.snippet.country-map .content {
    padding: 0;
    margin-top: .5rem;
}

/* TABS */
.snippet.country-map .content .tabs {
    border-bottom: solid 2px #435D7C;
}

.snippet.country-map .content .tabs .tab {
    background-color: #E2E7ED;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #435D7C;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 10px 25px;
}

.snippet.country-map .content .tabs .tab.active {
    background-color: #435D7C;
    color: #FFFFFF;
    cursor: default;
}

/* MAPS */
.snippet.country-map .content .map {
    width: 100%;
    height: 600px;
    background-color: #CDE8F7;
    z-index: 0;
}

.snippet.country-map .content .map .leaflet-interactive {
    cursor: default;
}

.snippet.country-map .content .map .map-location {    
    text-align: center;
    width: 80px !important;
    margin-left: -30px !important;
    height: 50px !important;
    margin-top: -25px !important;
}

.snippet.country-map .content .map .map-location .label {
    height: 36px;
}

.snippet.country-map .content .map .map-location .label img {
    width: 48px;
    height: 48px;
    filter: drop-shadow(0 0 4px #fff);
}

.snippet.country-map .content .map .map-location .label img,
.snippet.country-map .content .map .map-location .label div {
    display: inline-block;
}

.snippet.country-map .content .map .map-location .label .temp {
    color: #7B8EA8;
    font-weight: bold;    
    text-shadow: 0 0 2px #fff;
    transform: translateY(-18px) translateX(-5px);
}

.snippet.country-map .content .map .map-location .location {
    color: #5C6C7B;
    font-weight: bold;
    text-shadow: 0 0 4px #fff;
}

/* MAP RADAR */
/* overwrite weather map styling */
.country-map #map-radar.ip-weather-map .ip-timebar .ip-btn.ip-active,
.country-map #map-radar.ip-weather-map .ip-timebar .ip-timeline .ip-table tr td.ip-active {
    background-color: #435D7C;
    color: #fff;
}

.country-map #map-radar.ip-weather-map .ip-timebar .ip-btn {
    color: #435D7C;
}

@media (max-width: 40em) {
    .snippet.country-map .content .map {        
        height: 400px;
    }

    .snippet.country-map .content .tabs .tab {
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 3px 2px;
    }
}