.fullday table tr.weekend {
    background-color: #fafafa;
}

.fullday table tr:not(:last-of-type) td {
    border-bottom: solid 1px #f2f3f5;
}

.fullday table tr td {
    height: 84px;
    padding: 0;
}

.fullday table tr td.day div {
    text-align: left;
}

.fullday table tr td.day .text,
.fullday table tr td.temp .min {
    color: #879FBB;
}

.fullday table tr td.wxco {
    text-align: center;
}

.fullday table tr td.temp div{
    display: inline-block;
    font-weight: bold;
}

.fullday table tr td.rain .inner-wrapper,
.fullday table tr td.not-raining .inner-wrapper {
    padding-right: 10px;
    text-align: right;
}

.fullday table tr td.rain div,
.fullday table tr td.not-raining div {
    font-size: 13px;
}

.fullday table tr td.uv {
    font-size: 12px;
}

.fullday table tr td .font-small {
    font-size: 12px;
}

.fullday table tr td.rain span,
.fullday table tr td.uv span,
.fullday table tr td.not-raining span {
    font-size: 16px;
}

.fullday .mobile-only td.day {
    border: none;
}

.fullday table tr td.wind .wind-text {
    text-align: right;
}

.fullday table tr td.wind .wind-img {
    display: none;
}

/*WIDGET VERSION*/
.widget.fullday {
    max-height: 300px;
    max-width: 680px;
    overflow: hidden;
    padding: .5rem;
    width: 100%;
}
.widget.fullday>div {
    position: relative;
}

.widget.fullday .subtitle,
.widget.fullday .content {
    padding: 0;
}

.widget.fullday .title {
    padding: 0;
}

.widget.fullday .title h2 {
    overflow: hidden;
    text-overflow: ellipsis;
}

.widget.fullday .title .logo {
    max-height: 32px;
    position: absolute;
    right: 0;
    width: 200px;
}

.widget.fullday table tr td {
    height: 30px;
    padding: 0;
    text-align: center;
    width: 20%;
}

.widget.fullday table tr td.day div,
.widget.fullday table tr td.temp {
    text-align: center !important;
}

.widget.fullday table tr .wxco-icon {
    height: 33px !important;
}

.widget.fullday .wxco {
    display: block;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    width: 48px;
}

.widget.fullday .link {
    text-align: right;
    width: 100%;
}

@media (max-width: 550px) {
    .widget.fullday .title .logo {
        bottom: 5px;
        left: 0;
        width: 171px;
    }
    .widget .truncate a > span {
        display: none;
    }
}

@media (max-width: 40em) {
    .fullday table tr td {
        height: 50px;
    }

    .fullday table tr td.wxco-cell img.wxco {
        height: 30px;
        vertical-align: top;
        width: 30px;
    }
}