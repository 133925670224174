.actualDaypart .block .title {
    border-bottom: none;
}

.actualDaypart .block table .temp {
    color: #92A5BB;
    font-size: 40px;
    line-height: 64px;
    display: inline-block;
}
.actualDaypart .block table .wxco {
    float: left;
}

.actualDaypart .block table .rain-img,
.actualDaypart .block table .precip {
    display: inline-block;
}
.actualDaypart .block table .rain-img {
    width: 28px;
}
.actualDaypart .block table .rain-img img {
    vertical-align: sub;
    width: 20px;
    height: 17px;
}

.actualDaypart .block table .wind > div {
    display: inline-block;
}

.actualDaypart .block table .wind .img {
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 48px;
    font-size: .7rem;
    font-weight: bolder;
    padding-right: .5rem;
    padding-left: 3px;
}

.actualDaypart .block table tr th,
.actualDaypart .block table tr td {
    width: 25%;
}

.actualDaypart .block .shortTerm {
    padding-top: 20px;
}

.actualDaypart .block .link {
    margin-top: 1rem;
}