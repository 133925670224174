.badge {
    background-color: #435D7C;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.4em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
}
