.snippet {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.snippet .title {
    padding-bottom: .5rem;
}

.snippet .block {
    display: block;
    overflow: hidden;
}

.snippet .block .title {
    border-bottom: solid 2px #D4D9E3;
}

.snippet .block .subtitle {
    color: #879FBB; /*#99AEBF;*/
    padding: 3px 0
}

.snippet .block .title .btn {
    float: right;
}

.snippet .block .title .btn-group {
    float: right;
}

.snippet .title h1,
.snippet .title h2,
.snippet .title h3,
.snippet .title h4,
.snippet .title h5 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: .125rem;
}

.snippet .block .content,
.snippet .block > .footer {
    clear: both;
    padding: 1rem 0;
    border-bottom: solid 1px #D4D9E3;
}

.snippet .block > .footer {
    border-top: solid 2px #F4F4F4;
}

.snippet .async-content {
    opacity: 0;
}

@media screen and (max-width: 40em) {
    .snippet .title {
        padding: .625rem 0;
    }

    .snippet .block .title {
        padding: .625rem 0;
    }

    .snippet .block .content, .snippet .block > .footer {
        padding: .625rem 0;
    }
}
