.weekend .block table th,
.weekend .block table td {
    padding: .5rem 1rem;
}

.weekend .block table th {
    text-align: left;
    text-transform: none;
}

.weekend .block table .day .text,
.weekend .block table .temp .min {
    color: #879FBB;
}

.weekend .block table .day {
    vertical-align: baseline;
}

.weekend .block table .temp div {
    display: inline-block;
    font-weight: bold;
}

.weekend .block table .rain-img,
.weekend .block table .precip {
    display: inline-block;
}

.weekend .block table .rain-img {
    width: 28px;
}

.weekend .block table .rain-img img {
    vertical-align: sub;
    width: 20px;
    height: 17px;
}

.weekend .block table .wind > div {
    display: inline-block;
}

.weekend .block table .wind .img {
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 48px;
    font-size: .7rem;
    font-weight: bolder;
    padding-right: .5rem;
    padding-left: 3px;
}
