.location-search {
    min-width: 300px;
    padding: 1rem;
}

.location-search > div.color {
    background-color: #D2E7F5;
    padding: 2rem;
}

.location-search .title {
    border-bottom: none;
    text-align: center;
}

.location-search .content {
    border-bottom: none;
    height: 38px;
    padding-bottom: 1.5rem;
    position: relative;
}

.location-search .input {
    border: solid 1px #5D799A;
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    background-color: white;
    position: relative;
    display: flex;
}

.location-search .input img {
    padding: .5rem;
}

.location-search .loading {
    height: 16px;
    min-width: 16px;
    border-top: 2px solid #216bb6;
    border-right: 2px solid #216bb6;
    border-bottom: 2px solid #216bb6;
    border-left: 2px solid #ffffff;
    box-sizing: border-box;
    background-color: white;
    animation: spin 1.1s infinite linear;
    border-radius: 50%;
    display: flex;
    align-self: center;
    display: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.location-search .input img.clear {
    color: gray;
    cursor: pointer;
    display: none;
}

.location-search .results {
    background-color: #FFFFFF;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: thin dashed #D4D9E3;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 38px;
    z-index: 7;
    width: 100%;
}

.location-search .results .selected {
    background-color: #2F445E;
    color: #FFFFFF;
    text-decoration: none;
}

.location-search .results ul {
    margin: 0;
    padding: 0;
}

.search-result {
    margin: 0;
    width: 100%;
    list-style: none;
}

.search-result a {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #2F445E;
    box-sizing: border-box;
}

.search-result a span {
    margin-left: 10px;
}

.search-list-title {
    list-style: none;
}

.search-list-title div {
    padding: 6px 10px;
}