.tabs ul {
    border-bottom: solid 2px #435D7C;
    list-style: none;
    margin: 0;
    padding: 0;    
}

.tabs ul li {
    background-color: #E2E7ED;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    display: inline-block;
    padding: 10px 0;
}

.tabs ul li a {
    color: #2F445E;
    font-weight: bold;
    padding: 10px 30px;
}

/*Active & hover tab*/
.tabs ul li.active,
.tabs ul li:hover {
    background-color: #435D7C;
}

.tabs ul li.active a,
.tabs ul li:hover a {
    color: #FFFFFF;
}