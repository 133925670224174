/*RAIN STYLING*/
.rain {
    background-color: #EEFCFD;
}

.not-raining {
    color: #879FBB;
}

/*WIND*/
.wind-img {
    background-repeat: no-repeat;
    width: 24px;
    height: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 48px;
    font-size: .7rem;
    font-weight: bolder;
    padding-right: .5rem;
    padding-left: 3px;
}

@media (max-width: 40em) {
    .wind-img {
        padding-right: 4px;
    }
}

/*UV Index*/
.uvindex {
    color: #B7B7B7;
}

.uv-0, .uv-1, .uv-2 {
    color: #5F8C33;
}

.uv-3, .uv-4 {
    color: #F0A334;
}

.uv-5, .uv-6 {
    color: #EE5F00;
}

.uv-7, .uv-8 {
    color: #BB0202;
}

.uv-9, .uv-10, .uv-11, .uv-12, .uv-13 {
    color: #6A00CC;
}

.wxco {
    width: 64px;
    height: 64px;
}
.wxco.small {
    width: 32px;
    height: 32px;
}
.wxco.large {
    width: 96px;
    height: 96px;
}