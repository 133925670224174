.menu-footer .logo {
    width: 30%;
}

.menu-footer .logo a {
    width: 100%;
}

.menu-footer .all-texts {
    text-align: right;
}

.menu-footer .copyright,
.menu-footer .kvk,
.menu-footer .text {
    color: #FFFFFF;
    display: inline-block;
    line-height: 52px;
}

.menu-footer .settings {
    margin: 6px 0 10px 0;
    font-size: .9rem;
}

@media (min-width: 64em) {
    .menu-footer .logo {
        float: left;
    }
}

@media (max-width: 64em) {
    .menu-footer .logo {
        height: 30px;
        margin: 0 auto 5px auto;
        text-align: center;
        width: 280px;
    }

    .menu-footer .logo img {
        height: 30px;
        width: 280px;
    }

    .menu-footer .all-texts {
        text-align: center;
    }

    .menu-footer .copyright,
    .menu-footer .kvk,
    .menu-footer .text {
        line-height: 1.5;
    }
}