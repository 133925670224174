.country-location-list .btns {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.country-location-list .btns > div {
    padding-bottom: 1rem;
}
.country-location-list .columns {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
    list-style: none;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 40em) {
    .country-location-list .columns {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
}