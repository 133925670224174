table {
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    width: 100%;
}

table tr th {
    background-color: #F9FAFC;
    border-bottom: solid 1px #F2F3F5;
    padding: 1rem;
    text-transform: capitalize;
}

table tr td {
    padding: .5rem;
    text-align: center;
}

@media (max-width: 64em) {
    table tr td {
        padding: 1rem 0.5rem;
    }
}

@media (max-width: 40em) {
    table tr th,
    table tr td {
        padding: .5rem .25rem;
    }
}