.snow .title h1 .snowheight {
    color: #92A5BB;
}

.snow .title h1 .elevation {
    font-weight: normal;
}

.snow .content {
    position: relative;
}

.snow .mountain img{
    width: 100%;
}

.snow .resortStatus {
    color: #FFFFFF;
    padding:.5rem 1rem;
    position: absolute;
    top: 0;
}

.snow .snowdata {
    padding-left: 1rem;
    z-index: 3;
    position: relative;
}

.snow .snowdata .label,
.snow .snowdata .value {
    display: inline-block;
}

.snow .snowdata .snowheight {
    font-size: 20px;
}

.snow .snowheight .value {
    color: #0075B4;
}

.snow .gradient {
    background-image: linear-gradient(to top, #C2E0F1, #FFFFFF);
    bottom: 0;
    height: 25%;
    position: absolute;
    width: 100%;
}

@media (max-width: 40em) {
    .snow .mountain {
        margin-top: 30px;
    }
}