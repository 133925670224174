.fixed-ad-90 {
    display: flex;
    padding: 10px 0;
    height: 90px;
}

.fixed-ad-250 {
    display: flex;
    padding: 10px 0;
    height: 250px;
}
