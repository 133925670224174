*:not(input):not(textarea) {
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

html, body {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'SF Pro Display', 'SF Pro Text', 'SF Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #4E5F70;
    background-color: #FFFFFF;
    -webkit-overflow-scrolling: touch;
}


/* Floats */
.left {
    float: left;
}
.right {
    float: right;
}

.cms-block-container .wrapper_content {
    padding: 0.625rem 0;
}
.cms-block-container .wrapper,
.cms-block-container .wrapper_content {
    max-width: 1024px;
}

input, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    padding: 0.5rem;
    border: none;
    margin: 0 0 1rem;
    font-family: inherit;
    font-size: 1rem;
    color: #0a0a0a;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Disable default clear button at input with type="search" */
input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.heading-3 {
    font-size: 1.3rem;
}

.small-font {
    font-size: 14px;
}

p {
    line-height: 1.4;
    margin: 0 0 1rem 0;
}

a {
    color: #109FFF;
    cursor: pointer;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

a.right i,
a.left i {
    padding-right: 10px;
}

.link img,
.link a {
    vertical-align: middle;
}

a.disabled {
    color: #E2E7ED;
    cursor: default;
}

hr {
    border: none;
    margin: 0;
    border-bottom: 1px solid #F4F4F4;
    clear: both;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
}

img {
    border: none;
}

input, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    padding: 0.5rem;
    border: none;
    margin: 0 0 1rem;
    font-family: inherit;
    font-size: 1rem;
    color: #0a0a0a;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

[type='radio'] {
    display: inline-block;
    width: 13px;
    height: 13px;
    -webkit-appearance: radio;
}

[type='checkbox'] {
    display: inline-block;
    width: 13px;
    height: 13px;
    padding: 0;
    -webkit-appearance: checkbox;
}

input:focus, [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, textarea:focus {
    background-color: #fefefe;
    outline: none;
}

::selection {
    color: white;
    background: #1DB3E6;
}

.cursor-zoomin {
    cursor: zoom-in;
}

.cursor-zoomout {
    cursor: zoom-out;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-white {
    color: #fff;
}

.text-small {
    font-size: .9rem;
}

.truncate,
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.padding-none {
    padding: 0;
}

.padding {
    padding: 1rem;
}

.padding-left {
    padding-left: 1rem;
}

.padding-right {
    padding-right: 1rem;
}

.padding-top {
    padding-top: 1rem;
}

.padding-bottom {
    padding-bottom: 1rem;
}

.padding-left-none {
    padding-left: 0 !important;
}

.padding-right-none {
    padding-right: 0 !important;
}

.padding-top-none {
    padding-top: 0 !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-top-small {
    padding-top: .5rem;
}

.padding-left-small {
    padding-left: .5rem;
}

.padding-right-small {
    padding-right: .5rem;
}

.padding-bottom-small {
    padding-bottom: .5rem;
}

@media screen and (max-width: 40em) {
    p {
        margin: 0 0 .625rem 0;
    }

    .padding {
        padding: .625rem;
    }

    .padding-left {
        padding-left: .625rem;
    }

    .padding-right {
        padding-right: .625rem;
    }

    .padding-top {
        padding-top: .625rem;
    }

    .padding-bottom {
        padding-bottom: .625rem;
    }
}

@media screen and (min-width: 641px) {
    .padding-medium {
        padding: 1rem;
    }

    .padding-left-medium {
        padding-left: 1rem;
    }

    .padding-right-medium {
        padding-right: 1rem;
    }

    .padding-top-medium {
        padding-top: 1rem;
    }

    .padding-bottom-medium {
        padding-bottom: 1rem;
    }

    .padding-none-medium {
        padding: 0;
    }

    .padding-top-none-medium {
        padding-top: 0;
    }
}

@media screen and (min-width: 1025px) {
    .padding-large {
        padding: 1rem;
    }

    .padding-left-large {
        padding-left: 1rem;
    }

    .padding-right-large {
        padding-right: 1rem;
    }

    .padding-top-large {
        padding-top: 1rem;
    }

    .padding-bottom-large {
        padding-bottom: 1rem;
    }

    .padding-none-large {
        padding: 0;
    }

    .padding-top-none-large {
        padding-top: 0;
    }
}

.margin {
    margin: 1rem;
}

.margin-left {
    margin-left: 1rem;
}

.margin-right {
    margin-right: 1rem;
}

.margin-top {
    margin-top: 1rem;
}

.margin-bottom {
    margin-bottom: 1rem;
}

.margin-none {
    margin: 0;
}

.margin-top-small {
    margin-top: .5rem;
}

.margin-bottom-small {
    margin-bottom: .5rem;
}

.margin-left-small {
    margin-left: .5rem;
}

.margin-right-small {
    margin-right: .5rem;
}

.browser-warning {
    width: 100%;
    color: #333;
    background-color: #FFE018;
    border-bottom: solid 1px #333;
    padding: .5rem 1rem;
    font-size: .9rem;
    text-align: center;
}

.browser-warning img {
    background-color: #fff;
}

body > img {
    position: absolute;
}

.clearfix:after {
    display: block;
    clear: both;
    content: " ";
}

hr {
    border-color: #D4D9E3;
    margin: .5rem 0;
}

.font-bold {
    font-weight: bold;
}