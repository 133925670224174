.photo-collection .title h3 {
    display: inline-block;
}
.photo-collection .title a {
    line-height: 32px;
}

/*Picture hover*/
.photo-collection .active-photo .photo:hover {
    cursor: zoom-in;
}

.photo-collection .slider-panel .thumb {
    transform: scale(1);
    transition: transform 100ms ease-in-out;
}

.photo-collection .slider-panel .thumb:hover {
    cursor: pointer;
    transform: scale(1.03);
}

/*Slider*/
.photo-collection .slider {
    box-sizing: border-box;
    border: solid .5rem #fff;
    border-left: none;
    border-right: none;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.photo-collection .slider-panel {
    font-size: 0;
}


/*item photo*/
.photo-collection .item .item-photo {
    box-sizing: border-box;
    display: inline-block;
    padding-right: 5px;
}

.photo-collection .item .item-photo:last-of-type {
    padding-right: 0;
}

/*.photo-collection .item-photo > .col {
    padding: .5rem;
}*/

.photo-collection .item-photo.active .thumb {
    opacity: 0.5;
}

/*Next and previous sliders*/
.photo-collection .slider .prev,
.photo-collection .slider .next {
    background-color: #E2E7ED;
    border: solid 1px #D4D9E3;
    border-radius: 4px;
    color: #2F445E;
    height: 68px;
    margin-top: 5px;
    padding: 35px 5px 5px;
    position: absolute;
    top: 0;
    z-index: 7;
}

.photo-collection .slider .chevron-left {
    background-image: url(https://assets.infoplaza.io/site/weersvoorspelling.nl/icons/awesome/chevron-left.svg);
    height: 41px;
    width: 32px;
}

.photo-collection .slider .chevron-right {
    background-image: url(https://assets.infoplaza.io/site/weersvoorspelling.nl/icons/awesome/chevron-right.svg);
    height: 41px;
    width: 32px;
}

.photo-collection .slider .prev:hover,
.photo-collection .slider .next:hover {
    background-color: #435D7C;
    cursor: pointer;
}

.photo-collection .slider .prev:hover .chevron-left {
    background-image: url(https://assets.infoplaza.io/site/weersvoorspelling.nl/icons/awesome/chevron-left_white.svg);
}

.photo-collection .slider .next:hover .chevron-right {
    background-image: url(https://assets.infoplaza.io/site/weersvoorspelling.nl/icons/awesome/chevron-right_white.svg);
}

.photo-collection .prev {
    display: none;
    left: 0;
}

.photo-collection .next {
    display: none;
    right: 0;
}

.photo-collection .slider .next i,
.photo-collection .slider .prev i {
    font-size: xx-large;
}


/* pagination */
.photo-collection .slider-pagination {
    height: 22px;
    text-align: center;
}

.photo-collection .slider-pagination div {
    background: #2F445E;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    filter: Alpha(Opacity=50);
    height: 12px;
    margin: 5px 7px;
    opacity: 0.5;
    width: 12px;
}

.photo-collection .slider-pagination div.active {
    filter: Alpha(Opacity=100);
    opacity: 1;
}

/* active photo */
.photo-collection .active-photo .photo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: solid 1px #D4D9E3;
    border-radius: 4px;
    height: 500px;
    position: relative;
}

.photo-collection .active-photo .photo .photo-text,
.photo-collection .active-photo .photo .photo-count {
    box-sizing: border-box;
    color: #FFFFFF;
    padding: 5px 10px;
    position: absolute;
}

.photo-collection .active-photo .photo .photo-text {
    background-color: rgba(67, 93, 124, 0.8);
    bottom: 0;
    width: 100%;
}

.photo-collection .active-photo .photo .photo-count {
    background-color: rgba(67, 93, 124, 0.5);
    border-bottom-right-radius: 4px;
    top: 0;
}


.photoSmall {
    margin: 0 10px 10px 0;
}

.thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: solid 1px #D4D9E3;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 5px;
    height: 110px;
    width: 100%;
}

.meta .owner {
    font-weight: bold;
}

@media (max-width: 825px) {
    .photo-collection .active-photo .photo {
        height: 300px;
    }

    .photo-collection .slider .thumb {
        height: 80px;
    }
}
