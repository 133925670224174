.btn {
    background-color: #E2E7ED;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    color: #435D7C;
    display: block;
    font-size: .9rem;
    font-weight: bold;
    line-height: 1rem;
    margin: 1rem 0 0 0;
    min-width: 1.75rem;
    padding: .85em 1em;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-appearance: none;
}

.btn:hover {
    background-color: #435D7C;
    color: #FFFFFF;
    text-decoration: none;
}
