.top-list .block {
    border-radius: 5px;
}

.top-list .block .title {
    border-bottom: none;
    position: relative;
}

.top-list .title.hasImage {
    height: 206px;
    padding: 0;
}

.top-list .title.hasImage h3 {
    color: #FFF;
    padding: 5px;
    position: absolute;
    text-shadow: 1px 1px 2px #000;
    z-index: 1;
}

.top-list .title .list-image {
    position: absolute;
    height: 206px;
    width: 100%;
}

.top-list .title .list-image .img-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%
}

.top-list .list table tr:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 150ms ease-in-out
}

.top-list .list table tr:hover .name span {
    text-decoration: underline;
}

.top-list .list table tr:nth-child(even) {
    background-color: #fafafa;
}

.top-list .list table tr:not(:last-of-type) td {
    border-bottom: solid 1px #d4d9e3;
}

.top-list .list table tr td {
    color: #4e5f70;
    font-weight: bold;
    padding: .2rem;
    text-align: left;
}

.top-list .list table tr td:first-of-type {
    padding-left: .5rem!important;
}

.top-list .list table tr td:last-of-type {
    padding-right: .5rem!important;
}

.top-list .list table tr td a {
    display: block;
    color: #4e5f70;
}
.top-list .list table tr:hover td a {
    text-decoration: underline;
}

.top-list .list table tr td .wxco-icon {
    width: 30px;
}

.top-list .list table tr td.temp .min {
    color: #879FBB;
}