.text-light-blue {
    color: #00C1EF;
}

.text-orange {
    color: #FFB946;
}

.green {
    background-color: #5DA323;
}

.yellow {
    background-color: #FFAD00;
}

.red {
    background-color: #ED2329;
}