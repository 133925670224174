.modal {
    display: none;
    height: 100%;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.modal .modal-overlay {
    background-color: rgba(10, 10, 10, 0.45);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.modal .modal-dialog {
    background-color: #fff;
    left: 50%;
    max-width: 1092px;
    overflow: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
}

.modal .modal-dialog .modal-header .title {
    font-size: 1.5rem;
    font-weight: 500;
    padding: .625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal .modal-dialog .modal-header .image {
    text-align: center;
    padding: .625rem 0;
}

.modal .modal-dialog .modal-header button {
    float: right;
    width: 4rem;
    height: 3rem;
    font-size: 2rem;
    line-height: 1;
    font-weight: 500;
    cursor: pointer;
}

.modal .modal-dialog .modal-body {
    padding: 0 .625rem .625rem .625rem;
    box-sizing: border-box;
}

.modal.modal-image .modal-dialog .modal-body img {
    background-color: #f8f8f9;
    color: #bebec3;
    cursor: zoom-out;
    display: block;
    font-weight: 600;
    text-align: center;
    width: 100%;
}
.modal.modal-image .modal-dialog .modal-body .caption {
    padding: .5rem 0 0 0;
    font-size: .9rem;
}
