.country-top-list .vertical .top-list {
    padding-bottom: 1rem;
}

.country-top-list .top-list {
    padding: 0;
}

@media (min-width: 769px) {
    .country-top-list .horizontal .row .col {
        padding: 0 .5rem;
    }

    .country-top-list .horizontal .row .col:first-of-type {
        padding-left: 0;
    }

    .country-top-list .horizontal .row .col:last-of-type {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .country-top-list .horizontal .row .col {
        padding-bottom: 1rem;
    }
}