.hourly table tr td {
    border-bottom: solid 1px #f2f3f5;
    height: 64px;
    padding: 0;
}

.hourly .mobile-only td {
    border-bottom: none;
    text-align: left;
}

.hourly table tr td.hour {
    text-align: left;
}

.hourly table tr td.hour .head {
    line-height: 64px;
}

.hourly table tr td.hour .day {
    color: #879Fbb;
}

.hourly table tr td.wxco .col {
    height: 64px;
}

.hourly table tr td.wxco-cell {
    text-align: left;
}

.hourly table tr td.day {
    height: 32px;
}
.hourly table tr td.day .head {
    text-align: left;
}

.hourly table tr td.rain .inner-wrapper,
.hourly table tr td.not-raining .inner-wrapper {
    padding-right: 15px;
    text-align: right;
}

.hourly table tr td.rain div,
.hourly table tr td.not-raining div {
    font-size: 13px;
}

.hourly table tr td.rain span,
.hourly table tr td.not-raining span {
    font-size: 16px;
}

.hourly table tr td.wind .wind-text {
    text-align: right;
}

.hourly table tr td.sun img {
    transform: translateY(1px);
}

@media (max-width: 40em) {
    .hourly table tr td {
        border-bottom: solid 1px #f2f3f5;
        height: 50px;
        padding: 0;
    }

    .hourly table tr td.hour .head {
        line-height: 50px;
    }

    .hourly table tr td.wxco-cell img {
        height: 30px;
        vertical-align: top;
        width: 30px;
    }

    .hourly table tr td.wind .wind-img {
        display: none;
    }
}