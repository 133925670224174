.teaser {
    height: 150px;
}

.teaser .block {
    background-image: linear-gradient(#598CB6, #87BEE2);
    border-radius: 4px;
    height: 100%;
    transform: scale(1);
    transition: transform 150ms ease-in-out;
}

.teaser .block:hover{
    transform: scale(1.05);
}

.teaser .block .content {
    border-bottom: none;
    height: 100%;
    padding: 0;
}

.teaser .block .content a {
    height: 150px;
    width: 100%;
    padding: 1.5rem 1rem;
    display: flex;
    box-sizing: border-box;
}

.teaser .block .content a div:first-child {
    flex-grow: 1;
}
/*.teaser .block .content a div:last-child {
    width: 25%;
}*/


.teaser .block .content a .text {
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 22px;
    height: 100%;
    text-shadow: 1px 1px 2px #000;
}

.teaser.with-background .block {    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.teaser .block .content a .icon {
    height: 100%;
    position: relative;
}

.teaser .block .content a .icon img {
/*    position: absolute;
    top: 50%;
    right: 1rem;
    height: 40px;
    margin-top: -20px;*/
    margin-top: 20px;
}

.teaser .block .content a .icon img.arrow {
    margin-top: 0;
}

@media (max-width: 40em) {
    .teaser .block .content a .icon img.arrow {
        margin-top: -20px;        
    }
}