.forecast-astro .content .col {
    padding-top: 10px;
}

.forecast-astro .content .col6 {
    text-align: center;
}

.forecast-astro .sunrise {
    width: 86px;
    height: 79px;
    margin: 7px 0 0 0;
}

.forecast-astro .sunset {
    width: 86px;
    height: 86px;
}
