.menu-links {
    font-size: 0;
}

.menu-links ul {
    display: inline-block;
    font-size: 16px;
    list-style: none;
    padding: 0;
    vertical-align: text-top;
    width: 20%;
}

.menu-links .menu-title {
    color: #FFFFFF;
}

.menu-links a {
    color: #BFE5FF;
}

@media screen and (max-width: 768px) {
    .menu-links {
        columns: 2;
    }

    .menu-links ul {
        width: 100%;
    }

    .menu-links ul + .menu-links ul {
        margin-bottom: 20px;
    }
}